import React, { useState, useEffect } from "react";
import { FaUpload, FaMagic } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";

const EventPoster = ({ formData, setFormData }) => {
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  
  // Sync `PosterImage` with `PosterPreview` when editing an event
  useEffect(() => {
    if (formData.PosterImage) {
      setFormData((prevData) => ({
        ...prevData,
        PosterPreview: typeof formData.PosterImage === "string" ? formData.PosterImage : URL.createObjectURL(formData.PosterImage),
      }));
    }
  }, [formData.PosterImage, setFormData]);
  
  // Handle file upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          PosterImage: file, // Store the actual file
          PosterPreview: reader.result, // Store preview
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  
  // Generate poster using OpenAI
  const generatePoster = async () => {
    if (!prompt.trim()) {
      alert("Please enter a description for the poster.");
      return;
    }
    
    setLoading(true);
    try {
      const endpoint = "https://api.openai.com/v1/images/generations";
      const headers = {
        Authorization: `Bearer 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`, // Replace with your OpenAI API key
        "Content-Type": "application/json",
      };
      const context = "Create a visually stunning event graphics with vibrant visuals and engaging colors. Avoid Typography.";
      const fullPrompt = `${prompt}. ${context}`;
      
      const body = JSON.stringify({
        prompt: fullPrompt,
        n: 1,
        size: "512x512",
      });
      
      const response = await fetch(endpoint, {
        method: "POST",
        headers,
        body,
      });
      
      const data = await response.json();
      
      if (data.error) {
        alert(`API Error: ${data.error.message}`);
      } else if (data.data && data.data[0]?.url) {
        setFormData((prevData) => ({
          ...prevData,
          PosterImage: data.data[0].url, // Store the generated URL as `PosterImage`
          PosterPreview: data.data[0].url, // Display preview
        }));
      } else {
        alert("Failed to generate poster. Please try again.");
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      alert("An unexpected error occurred: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="form-group mt-4 mb-4">
    <label className="form-label font-weight-bold text-primary fw-bold fs-5">
    Event Poster:
    </label>
    <small className="form-text text-muted mb-2">
    Upload your custom event poster or use AI to create a visually compelling one based on your event details.
    </small>
    
    {/* Poster Upload Area */}
    <Row className="mt-4">
    {/* Upload Box */}
    <Col xs={12} md={6} className="d-flex justify-content-center">
    <div
    style={{
      position: "relative",
      width: "100%", // Takes full width on small screens
      maxWidth: "500px", // Limits size on larger screens
      height: "300px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `url(${formData.PosterPreview || "path/to/placeholder-image.jpg"})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderRadius: "10px",
      overflow: "hidden",
      border: "2px solid #e0e0e0",
    }}
    >
    {/* File Upload Input */}
    <input
    type="file"
    accept="image/*"
    onChange={handleFileChange}
    className="w-100 h-100 position-absolute"
    style={{ opacity: 0, cursor: "pointer", zIndex: 2 }}
    />
    
    {/* Centered Upload Placeholder */}
    {!formData.PosterPreview && (
      <div
      className="d-flex flex-column align-items-center justify-content-center text-center"
      style={{
        position: "absolute",
        width: "200px",
        height: "150px",
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        borderRadius: "20px",
        border: "2px dashed #ccc",
        zIndex: 1,
      }}
      >
      <FaUpload size={30} style={{ marginBottom: "8px", color: "#007bff" }} />
      <span className="fw-bold text-dark">Upload photo</span>
      </div>
    )}
    </div>
    </Col>
    
    {/* Poster Guidelines */}
    <Col xs={12} md={6} className="d-flex align-items-center justify-content-center">
    <div className="w-100" style={{ maxWidth: "500px" }}>
    <small className="form-text text-muted">
    <ul className="mb-0">
    <li>Recommended image aspect ratio: 2/3 or 3/4</li>
    <li>Recommended image size: 512 x 768px</li>
    <li>Supported image types: JPG, PNG</li>
    <li>Ensure the file size does not exceed 1 MB</li>
    </ul>
    </small>
    </div>
    </Col>
    </Row>
    
    {/* Generate Poster Section */}
    <div className="mt-4">
    <label className="form-label font-weight-bold text-secondary">
    Generate a Poster:
    </label>
    <div className="input-group">
    <input
    type="text"
    className="form-control shadow-sm"
    value={prompt}
    onChange={(e) => setPrompt(e.target.value)}
    placeholder="Enter a description for the poster (e.g., Tech seminar with AI themes)"
    style={{ borderRadius: "8px 0 0 8px" }}
    />
    <button
    className="btn btn-primary"
    type="button"
    onClick={generatePoster}
    disabled={loading}
    style={{ borderRadius: "0 8px 8px 0" }}
    >
    {loading ? "Generating..." : "Generate"}
    </button>
    </div>
    <small className="form-text text-muted">
    Use this tool to create a poster based on your event theme and details.
    </small>
    </div>
    </div>
  );
};

export default EventPoster;


  