import React, { useState, useEffect } from "react";

const AboutEvent = ({ formData, setFormData }) => {
  const [aboutText, setAboutText] = useState(formData.AboutEvent || "");
  const [prompt, setPrompt] = useState(""); // State for user prompt
  const [loading, setLoading] = useState(false);
  
  // Sync aboutText with formData when editing an event
  useEffect(() => {
    setAboutText(formData.AboutEvent || "");
  }, [formData.AboutEvent]);
  
  // Handle text change
  const handleTextChange = (e) => {
    const value = e.target.value;
    setAboutText(value);
    setFormData((prevData) => ({
      ...prevData,
      AboutEvent: value, // Ensure formData is updated
    }));
  };
  
  const generateDescription = async () => {
    if (!prompt.trim()) {
      alert("Please enter a prompt before generating the description.");
      return;
    }
    
    setLoading(true);
    try {
      const endpoint = "https://api.openai.com/v1/chat/completions";
      const headers = {
        Authorization: `Bearer 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`, // Replace with your OpenAI API key
        "Content-Type": "application/json",
      };
      
      const body = JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [
          {
            role: "system",
            content: "You are an assistant that generates detailed and professional event descriptions for organizers.",
          },
          {
            role: "user",
            content: `Describe the following event in a detailed and professional manner, make it one whole paragraph: ${prompt}`,
          },
        ],
        temperature: 0.8,
        max_tokens: 512,
        top_p: 0.9,
      });
      
      const response = await fetch(endpoint, {
        method: "POST",
        headers,
        body,
      });
      
      const data = await response.json();
      if (response.status === 200 && data.choices) {
        const generatedText = data.choices[0].message.content.trim();
        setAboutText(generatedText);
        setFormData((prevData) => ({
          ...prevData,
          AboutEvent: generatedText, // Ensure formData is updated
        }));
      } else {
        console.error("Error Response:", data);
        alert("Error generating description. Please try again.");
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      alert("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="form-group mt-4">
    {/* Prompt Input with Generate Button */}
    <label className="form-label text-success fw-bold" style={{ fontSize: '1rem' }}>
    About Event:
    </label>
    <div className="input-group shadow-sm border-primary mb-3" style={{ borderRadius: "8px" }}>
    <input
    type="text"
    className="form-control"
    value={prompt}
    onChange={(e) => setPrompt(e.target.value)}
    placeholder="Enter a brief event topic to get an AI draft (e.g., Tech seminar on AI advancements)."
    style={{
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      borderRight: "none",
    }}
    />
    <button
    className="btn btn-primary"
    onClick={generateDescription}
    disabled={loading}
    style={{
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    }}
    >
    {loading ? "..." : "Generate"}
    </button>
    </div>
    
    {/* About Event Textarea */}
    <textarea
    className="form-control shadow-sm border-primary"
    name="AboutEvent"
    value={aboutText}
    onChange={handleTextChange} // Corrected to update formData.AboutEvent
    style={{
      height: "250px",
      borderRadius: "8px",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
    }}
    placeholder="Your generated event description will appear here..."
    ></textarea>
    
    {/* Character Count and Example */}
    <div className="d-flex justify-content-between mt-2">
    <small className="text-muted">
    Example: "Join us for an unforgettable evening at the Annual Music Festival featuring live performances from top artists, gourmet food trucks, and interactive art installations."
    </small>
    <small className="text-muted">{aboutText.length}/1000 characters</small>
    </div>
    </div>
  );
};

export default AboutEvent;