// CreateEvent.js

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Confetti from "react-confetti"; // Import react-confetti
import 'react-datepicker/dist/react-datepicker.css';
import { FaPlus, FaTrash } from 'react-icons/fa'; // Import FontAwesome icons
import { API_ENDPOINTS } from '../config';
import { FaUpload } from 'react-icons/fa';
import EventTypeSelector from './EventTypeSelector'
import EventTimeDetails from './EventTimeDetails'
import AboutEvent from './AboutEvent'
import VenueInfo from './VenueInfo.js'
import EventPoster from './EventPoster.js'

const EventForm = ({ editingEvent = null }) => {
    const { id } = useParams(); // Get event ID from the URL
    const navigate = useNavigate(); // For redirecting after successful event creation/edit
    
    const [formData, setFormData] = useState({
        EventName: '',
        EventType: '',
        UserID: '',
        EventDate: new Date(),
        EventCity: '',
        EventCountry: '',
        StartTime: '',
        EndTime: '',
        DoorsOpenTime: '',
        VenueName: '',
        VenueAddress: '',
        VenueDetails: '',
        Theme: '',
        DressCode: '',
        ContactEmail: '',
        ContactPhone: '',
        CovidProtocol: '',
        AccessibilityInfo: '',
        PosterImage: null,
        OrganizerName: '',
        SeatMap: null,
        AboutEvent: '',
    });
    
//  const [tickets, setTickets] = useState([{
//      TicketType: '',
//      Price: '',
//      Availability: '',
//      PurchaseStartDate: new Date(),
//      PurchaseDeadline: new Date(),
//      AbsorbServiceFee: false,
//      TicketDetails: '',
//      Notes: '13% HST will be added at checkout.',
//  }]);
    
    const [successMessage, setSuccessMessage] = useState('');
    const [showConfetti, setShowConfetti] = useState(false); // Track confetti state

    const userId = localStorage.getItem('userId');
    
    // Sanitize a date value to ensure it's valid
    const sanitizeDate = (date) => {
        return date && !isNaN(new Date(date)) ? new Date(date) : null;
    };
    
//  // Fetch ticket data for a specific event
//  const fetchTicketData = async (eventId) => {
//      try {
//          const response = await fetch(API_ENDPOINTS.getEventTickets(eventId));
//          if (!response.ok) throw new Error('Failed to fetch ticket data');
//          const data = await response.json();
//          console.log('Ticket data:', data);
//          const sanitizedTickets = (data.tickets || []).map(ticket => ({
//              ...ticket,
//              PurchaseStartDate: sanitizeDate(ticket.PurchaseStartDate),
//              PurchaseDeadline: sanitizeDate(ticket.PurchaseDeadline),
//          }));
//          setTickets(sanitizedTickets);
//      } catch (error) {
//          console.error('Error fetching ticket data:', error);
//          setTickets([]); // Fallback to empty array
//      }
//  };
    
    // UseEffect to populate form when editingEvent changes

    useEffect(() => {
        if (editingEvent) {
            console.log('Editing event detected:', editingEvent);
            console.log('poster image:', editingEvent.PosterImagePath)
            setFormData((prevFormData) => ({
                ...prevFormData,
                EventName: editingEvent.EventName || '',
                EventType: editingEvent.EventType || '',
                UserID: editingEvent.UserID || userId || '', // Ensure userId from localStorage is used if editingEvent.UserID is empty
                EventDate: sanitizeDate(editingEvent.EventDate),
                EventCity: editingEvent.EventCity || '',
                EventCountry: editingEvent.EventCountry || '',
                StartTime: editingEvent.StartTime || '',
                EndTime: editingEvent.EndTime || '',
                DoorsOpenTime: editingEvent.DoorsOpenTime || '',
                VenueName: editingEvent.VenueName || '',
                VenueAddress: editingEvent.VenueAddress || '',
                VenueDetails: editingEvent.VenueDetails || '',
                Theme: editingEvent.Theme || '',
                DressCode: editingEvent.DressCode || '',
                ContactEmail: editingEvent.ContactEmail || '',
                ContactPhone: editingEvent.ContactPhone || '',
                CovidProtocol: editingEvent.CovidProtocol || '',
                AccessibilityInfo: editingEvent.AccessibilityInfo || '',
                PosterImage: editingEvent.PosterImagePath || null,
                OrganizerName: editingEvent.OrganizerName || '',
                SeatMap: editingEvent.SeatMap || null,
                AboutEvent: editingEvent.AboutEvent || '',
            }));
        } else {
            // Ensure UserID is set when creating a new event
            setFormData((prevFormData) => ({
                ...prevFormData,
                UserID: userId || '',
            }));
        }
    }, [editingEvent, userId]);
    
//  useEffect(() => {
//      if (editingEvent) {
//          console.log('Editing event detected:', editingEvent);
//          setFormData({
//              ...formData,
//              EventName: editingEvent.EventName || '',
//              EventType: editingEvent.EventType || '',
//              UserID: editingEvent.UserID || userId || '',
//              EventDate: sanitizeDate(editingEvent.EventDate),
//              EventCity: editingEvent.EventCity || '',
//              EventCountry: editingEvent.EventCountry || '',
//              StartTime: editingEvent.StartTime || '',
//              EndTime: editingEvent.EndTime || '',
//              DoorsOpenTime: editingEvent.DoorsOpenTime || '',
//              VenueName: editingEvent.VenueName || '',
//              VenueAddress: editingEvent.VenueAddress || '',
//              VenueDetails: editingEvent.VenueDetails || '',
//              Theme: editingEvent.Theme || '',
//              DressCode: editingEvent.DressCode || '',
//              ContactEmail: editingEvent.ContactEmail || '',
//              ContactPhone: editingEvent.ContactPhone || '',
//              CovidProtocol: editingEvent.CovidProtocol || '',
//              AccessibilityInfo: editingEvent.AccessibilityInfo || '',
//              PosterImage: editingEvent.PosterImage || null,
//              OrganizerName: editingEvent.OrganizerName || '',
//              SeatMap: editingEvent.SeatMap || null,
//              AboutEvent: editingEvent.AboutEvent || '',
//          });
//          
//          //fetchTicketData(editingEvent.EventID).catch(error => console.error('Error fetching tickets:', error));
//      }
//  }, [editingEvent]);
    
    // Handle form changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    
    // Handle date changes
    const handleDateChange = (date) => {
        setFormData((prevData) => ({ ...prevData, EventDate: date }));
    };
    
//  // Handle ticket changes
//  const handleTicketChange = (index, e) => {
//      const { name, value } = e.target;
//      const updatedTickets = [...tickets];
//      updatedTickets[index][name] = value;
//      setTickets(updatedTickets);
//  };
//  
//  const handleServiceFeeChange = (index, e) => {
//      const updatedTickets = [...tickets];
//      updatedTickets[index].AbsorbServiceFee = e.target.checked;
//      setTickets(updatedTickets);
//  };
//  
//  const handlePurchaseStartDateChange = (index, date) => {
//      const updatedTickets = [...tickets];
//      updatedTickets[index].PurchaseStartDate = sanitizeDate(date);
//      setTickets(updatedTickets);
//  };
//  
//  const handleTicketDateChange = (index, date) => {
//      const updatedTickets = [...tickets];
//      updatedTickets[index].PurchaseDeadline = sanitizeDate(date);
//      setTickets(updatedTickets);
//  };
//  
//  // Add or remove tickets
//  const addTicket = () => setTickets([...tickets, {
//      TicketType: '',
//      Price: '',
//      Availability: '',
//      PurchaseStartDate: new Date(),
//      PurchaseDeadline: new Date(),
//      AbsorbServiceFee: false,
//      TicketDetails: '',
//      Notes: '13% HST will be added at checkout.',
//  }]);
//    
//    const removeTicket = (index) => setTickets(tickets.filter((_, i) => i !== index));
    
    // Handle file change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSize = 1 * 1024 * 1024; // 1 MB size limit
        
        if (file) {
            // Check if file exceeds the size limit
            if (file.size > maxSize) {
                alert("File size exceeds the 1 MB limit.");
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    PosterImage: null, // Reset to null if the file is too large
                }));
            } else {
                // Generate a preview URL for the uploaded file
                const previewURL = URL.createObjectURL(file);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    PosterImage: file,
                    PosterPreview: previewURL, // Add the preview URL
                }));
            }
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                PosterImage: null,
                PosterPreview: null, // Reset preview when no file is provided
            }));
        }
    };
    
    useEffect(() => {
        console.log('Updated formData', formData.PosterImage);
    }, [formData.PosterImage]);

    useEffect(() => {
        console.log('Editing Event:', editingEvent);
    }, [editingEvent]);

    // Handle form submission
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        console.log('formData',formData)
        
        if (!formData.EventName.trim() || !formData.VenueName.trim()) {
            alert('Event Name and Venue Name are required');
            return;
        }
        
        // Ensure `editingEvent` is valid before using it
        const eventId = editingEvent?.EventID;
        const endpoint = eventId ? API_ENDPOINTS.updateEvent(eventId) : API_ENDPOINTS.createEvent;
        const method = eventId ? 'PUT' : 'POST';
        
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]); // Append all form fields
        }
        //data.append('tickets', JSON.stringify(tickets)); // Append tickets as JSON string
        
        try {
            const response = await fetch(endpoint, {
                method: method,
                body: data,
            });
            
            if (!response.ok) {
                const errorMessage = await response.text();
                console.error('Failed to submit event:', errorMessage);
                alert(`Failed to ${eventId ? 'update' : 'create'} event. ${errorMessage}`);
                return;
            }
            
            setSuccessMessage(eventId ? 'Event updated successfully!' : 'Event created successfully!');
            // Trigger confetti effect on successful submission
            setShowConfetti(true);
            setTimeout(() => {
                setShowConfetti(false);
                navigate("/userpanel");
            }, 5000); // Keep confetti for 5 seconds
                        
        } catch (error) {
            console.error('Error:', error);
            alert(`An error occurred: ${error.message}`);
        }
    };
    
    
    const themesOptions = [
        { value: 'Corporate', label: 'Corporate' },
        { value: 'Vintage', label: 'Vintage' },
        { value: 'Tropical', label: 'Tropical' },
        { value: 'Masquerade', label: 'Masquerade' },
        { value: 'Carnival', label: 'Carnival' },
        { value: 'Hollywood', label: 'Hollywood' },
        { value: 'Garden Party', label: 'Garden Party' },
        { value: 'Great Gatsby', label: 'Great Gatsby' },
        { value: 'Sports', label: 'Sports' },
        { value: 'Fantasy', label: 'Fantasy' },
        { value: 'Casino Night', label: 'Casino Night' },
        { value: 'Retro', label: 'Retro' },
        { value: 'Space Odyssey', label: 'Space Odyssey' },
        { value: 'Beach', label: 'Beach' },
        { value: 'Fairy Tale', label: 'Fairy Tale' },
    ];
    
    const dressCodeOptions = [
        { value: 'Dress to Impress', label: 'Dress to Impress' },
        { value: 'Formal', label: 'Formal' },
        { value: 'Semi-Formal', label: 'Semi-Formal' },
        { value: 'Casual', label: 'Casual' },
        { value: 'Business Casual', label: 'Business Casual' },
        { value: 'Black Tie', label: 'Black Tie' },
        { value: 'White Tie', label: 'White Tie' },
        { value: 'Cocktail Attire', label: 'Cocktail Attire' },
        { value: 'Festive Attire', label: 'Festive Attire' },
        { value: 'Themed Costume', label: 'Themed Costume' },
        { value: 'Smart Casual', label: 'Smart Casual' },
        { value: 'Beach Attire', label: 'Beach Attire' },
        { value: 'Cultural Attire', label: 'Cultural Attire' },
        { value: 'Athleisure', label: 'Athleisure' },
        { value: 'No Dress Code', label: 'No Dress Code' },
    ];
    
    const handleMultiSelectChange = (selectedOptions, fieldName) => {
        setFormData((prevData) => ({
            ...prevData,
            [fieldName]: selectedOptions.map((option) => option.value),
        }));
    };
    
    return (
        <Container className="mt-5 p-0">
        <Row className="justify-content-center px-0">
        <Col xs={12} md={12} lg={12} className="p-0"> {/* This ensures the form is full-width on smaller screens */}
        <Card className="shadow-lg p-0 px-0 mx-0">
        <Card.Body>
        <div className="card-header p-0">{id ? 'Edit Event' : 'Create Event'}</div>
        <div className="card-body p-0">
        <form onSubmit={handleSubmit}>
        {/* Event Information */}
        <label class="text-primary fw-bold fs-5">Event Information:</label>
        
        
        <div className="form-group mb-4 px-0">
        <Row className="g-3">
        {/* Event Name */}
        <Col lg={6} md={6} sm={12}>
        <Form.Group>
        <Form.Control
        type="text"
        placeholder="Enter Event Name"
        className="shadow-sm border-primary"
        name="EventName"
        value={formData.EventName}
        onChange={handleChange}
        style={{ borderRadius: '8px' }}
        />
        </Form.Group>
        </Col>
        
        {/* Event Organizer */}
        <Col lg={6} md={6} sm={12}>
        <Form.Group>
        <Form.Control
        type="text"
        placeholder="Enter Organizer Name"
        className="shadow-sm border-success"
        name="OrganizerName"
        value={formData.OrganizerName}
        onChange={handleChange}
        style={{ borderRadius: '8px' }}
        />
        </Form.Group>
        </Col>
        
        {/* Organizer Contact Email */}
        <Col lg={6} md={6} sm={12}>
        <Form.Group>
        <Form.Control
        type="email"
        placeholder="Enter Contact Email"
        className="shadow-sm border-danger"
        name="ContactEmail"
        value={formData.ContactEmail}
        onChange={handleChange}
        style={{ borderRadius: '8px' }}
        />
        </Form.Group>
        </Col>
        
        {/* Organizer Phone */}
        <Col lg={6} md={6} sm={12}>
        <Form.Group>
        <Form.Control
        type="tel"
        placeholder="Enter Phone Number"
        className="shadow-sm border-warning"
        name="ContactPhone"
        value={formData.ContactPhone}
        onChange={handleChange}
        style={{ borderRadius: '8px' }}
        />
        </Form.Group>
        </Col>
        </Row>
        </div>


        {/* EventTypeSelector Component */}
        <EventTypeSelector formData={formData} setFormData={setFormData} />

        {/* EventTimeDetails Component */}
        <EventTimeDetails formData={formData} setFormData={setFormData} />
                
                

        <div className="form-group">
        <div className="row gy-3">
        {/* Event City */}
        <div className="col-lg-6 col-md-6 col-12">
        <label className="form-label text-primary fw-bold" style={{ fontSize: '1rem' }}>
        Event City:
        </label>
        <input
        type="text"
        className="form-control shadow-sm border-primary"
        name="EventCity"
        value={formData.EventCity}
        onChange={handleChange}
        placeholder="Enter City"
        style={{ borderRadius: '8px' }}
        />
        </div>
        
        {/* Event Country */}
        <div className="col-lg-6 col-md-6 col-12">
        <label className="form-label text-success fw-bold" style={{ fontSize: '1rem' }}>
        Event Country:
        </label>
        <select
        className="form-select shadow-sm border-success"
        name="EventCountry"
        value={formData.EventCountry}
        onChange={handleChange}
        style={{ borderRadius: '8px' }}
        >
        <option value="" disabled>Select Country</option>
        <option value="United States">United States</option>
        <option value="Canada">Canada</option>
        </select>
        </div>
        </div>
        </div>      
        

        <div className="row gy-3">
        {/* Event Theme */}
        <div className="col-lg-6 col-md-6 col-12">
        <label className="form-label text-primary fw-bold" style={{ fontSize: '1rem' }}>
        Event Theme:
        </label>
        <Select
        isMulti
        options={themesOptions}
        value={themesOptions.filter((option) => formData.Theme?.includes(option.value))}
        onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, 'Theme')}
        className="basic-multi-select shadow-sm border-primary"
        classNamePrefix="select"
        placeholder="Select Themes"
        />
        </div>
        
        {/* Dress Code */}
        <div className="col-lg-6 col-md-6 col-12">
        <label className="form-label text-success fw-bold" style={{ fontSize: '1rem' }}>
        Dress Code:
        </label>
        <Select
        isMulti
        options={dressCodeOptions}
        value={dressCodeOptions.filter((option) => formData.DressCode?.includes(option.value))}
        onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, 'DressCode')}
        className="basic-multi-select shadow-sm border-success"
        classNamePrefix="select"
        placeholder="Select Dress Codes"
        />
        </div>
        </div>

        {/* EventTimeDetails Component */}
        <AboutEvent formData={formData} setFormData={setFormData} />
                
        <div
        className="my-4 mx-auto"
        style={{
            width: "95%",
            height: "5px", // Line thickness
            backgroundColor: "#007bff", // Bootstrap primary color
            borderRadius: "2px", // Optional: Rounded edges
        }}
        ></div>
        
        {/* VenueInformation Component */}
        <VenueInfo formData={formData} setFormData={setFormData} />
        
        <div
        className="my-4 mx-auto"
        style={{
            width: "95%",
            height: "5px", // Line thickness
            backgroundColor: "#007bff", // Bootstrap primary color
            borderRadius: "2px", // Optional: Rounded edges
        }}
        ></div>
        
        <div className="form-group">
        <div className="row gy-3">
        {/* Seat Map Requirement */}
        <div className="col-lg-4 col-md-4 col-12">
        <label className="form-label text-primary fw-bold" style={{ fontSize: '1rem' }}>
        Seat Map Requirement:
        </label>
        <select
        className="form-select shadow-sm border-primary"
        name="SeatMapRequired"
        value={formData.SeatMapRequired}
        onChange={handleChange}
        style={{ borderRadius: '8px' }}
        >
        <option value="" disabled>Select Seat Map Option</option>
        <option value="1">Yes</option>
        <option value="0">No</option>
        </select>
        <small className="form-text text-muted mt-1">
        If you select "Yes," please email your event seat map to <a href="mailto:info@eventtop.ca" className="text-primary">info@eventtop.ca</a> for integration.
        </small>
        </div>
        
        {/* COVID Protocol */}
        <div className="col-lg-4 col-md-4 col-12">
        <label className="form-label text-success fw-bold" style={{ fontSize: '1rem' }}>
        COVID Protocol:
        </label>
        <select
        className="form-select shadow-sm border-success"
        name="CovidProtocol"
        value={formData.CovidProtocol}
        onChange={handleChange}
        style={{ borderRadius: '8px' }}
        >
        <option value="" disabled>Select COVID Protocol</option>
        <option value="Required">Required</option>
        <option value="Recommended">Recommended</option>
        <option value="Not Required">Not Required</option>
        </select>
        </div>
        
        {/* Accessibility Info */}
        <div className="col-lg-4 col-md-4 col-12">
        <label className="form-label text-warning fw-bold" style={{ fontSize: '1rem' }}>
        Accessibility Info:
        </label>
        <select
        className="form-select shadow-sm border-warning"
        name="AccessibilityInfo"
        value={formData.AccessibilityInfo}
        onChange={handleChange}
        style={{ borderRadius: '8px' }}
        >
        <option value="" disabled>Select Accessibility Option</option>
        <option value="Wheelchair Accessible">Wheelchair Accessible</option>
        <option value="Hearing Assistance Available">Hearing Assistance Available</option>
        <option value="Not Applicable">Not Applicable</option>
        </select>
        </div>
        </div>
        </div>
        
        <div
        className="my-4 mx-auto"
        style={{
            width: "95%",
            height: "5px", // Line thickness
            backgroundColor: "#007bff", // Bootstrap primary color
            borderRadius: "2px", // Optional: Rounded edges
        }}
        ></div>
        
        
        
        <EventPoster formData={formData} setFormData={setFormData} />

        
        
        <div
        className="my-4 mx-auto"
        style={{
            width: "95%",
            height: "5px", // Line thickness
            backgroundColor: "#007bff", // Bootstrap primary color
            borderRadius: "2px", // Optional: Rounded edges
        }}
        ></div>
        
        
        
        

                

        
        <button type="submit" className="btn btn-primary mt-3">
        {editingEvent ? 'Update Event' : 'Create Event'}
        </button>
        
        {successMessage && (
            <div className="alert alert-success mt-3" role="alert">
            {successMessage}
            </div>
        )}
        </form>
        </div>
        </Card.Body>
        </Card>
        </Col>
        </Row>
        
        {/* Confetti Celebration */}
        {showConfetti && <Confetti />}
        
        </Container>
    );
};

export default EventForm;