import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { API_ENDPOINTS } from '../config'; // Import API endpoint configuration
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Icons for toggling password visibility
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyDHGi_-G3GRX_aYV9Gc2spBMYN7kvbA4h4",
  authDomain: "eventtop-ca.firebaseapp.com",
  projectId: "eventtop-ca",
  storageBucket: "eventtop-ca.appspot.com",
  messagingSenderId: "YOUR_MESSAGING_SENDER_ID",
  appId: "YOUR_APP_ID",
  measurementId: "G-XYZ12345",
  clientId: "738545984579-sfjblbfb9u9do4gvt6sbhdr5u42apkkp.apps.googleusercontent.com",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const SignInForm = ({ setUserData }) => {
  // States
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Handle input changes for the form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle Google Sign-In
  const handleGoogleSignIn = async () => {
    setLoading(true); // Start loading indicator
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idToken = await user.getIdToken(); // Retrieve the Google ID token

      // Send the Google user details to the backend
      const response = await fetch(API_ENDPOINTS.googleLogin, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          googleId: idToken,
          email: user.email,
          displayName: user.displayName,
        }),
      });

      if (!response.ok) throw new Error('Google Sign-In failed');

      const data = await response.json();
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('userId', data.userId); // Save user ID locally
      setUserData(data); // Update user context
      navigate('/userpanel'); // Redirect to user panel
    } catch (error) {
      console.error('Error during Google Sign-In:', error);
      setError('Google Sign-In failed');
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  // Handle conventional login submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const response = await fetch(API_ENDPOINTS.login, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      if (!response.ok) throw new Error('Sign-in failed');

      const data = await response.json();
      localStorage.setItem('accessToken', data.accessToken);
      localStorage.setItem('userId', data.userId); // Save user ID locally
      setUserData(data); // Update user context
      navigate('/userpanel'); // Redirect to user panel
    } catch (error) {
      console.error('Error during sign-in:', error);
      setError('Invalid username or password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid mt-2">
      <div className="row justify-content-center p-3">
        <div className="col-12 col-md-8 col-lg-8 border rounded border-gray p-3 d-flex flex-column flex-md-row align-items-center">
          {/* Left Image Section */}
          <div
            className="me-3 d-none d-md-block flex-shrink-1"
            style={{ width: '45%', height: '100%' }}
          >
            <img
              src="images/photo-signin.jpeg"
              alt="Sign in"
              className="img-fluid w-100 h-100"
            />
          </div>

          {/* Form Content Section */}
          <div className="w-100">

          <h2
          className="text-center mb-4"
          style={{
            background: 'linear-gradient(90deg, #FFD700, #FF7F00, #FFFF00, #00FF00, #0000FF, #4B0082, #8F00FF)', // Rainbow gradient
            WebkitBackgroundClip: 'text', // Clips background to text
            WebkitTextFillColor: 'transparent', // Makes text transparent to show the gradient
            WebkitTextStroke: '0.2px black', // Thin black border around characters
            fontWeight: 'bold',
            fontSize: '2.5rem', // Larger font size for emphasis
          }}
          >
          Welcome Back
          </h2>

            {/* Google Sign-In Section */}
            <div className="text-center my-3 p-3 border rounded shadow-md mx-auto" style={{ width: '90%' }}>
            <button
            className="btn d-flex align-items-center justify-content-center w-100"
            onClick={handleGoogleSignIn}
            disabled={loading}
            style={{
              backgroundColor: '#FFFFFF', // White background
              color: '#4285F4',           // Google Blue for text
              border: '1px solid #DDDDDD', // Light gray border
              borderRadius: '4px',
              padding: '10px 20px',
              fontWeight: '500',
              fontSize: '16px',
              transition: 'box-shadow 0.3s ease',
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = '#1167D6')} // Darker on hover
            onMouseLeave={(e) => (e.target.style.backgroundColor = '#FFFFFF')} // Back to original
            >
            <img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/240px-Google_%22G%22_logo.svg.png"
            alt="Google Icon"
            style={{
              height: '20px',
              width: '20px',
              marginRight: '8px',
            }}
            />
            {loading ? 'Processing...' : 'Continue with Google'}
            </button>
            </div>


            {/* Display Error if Exists */}
            {error && <div className="alert alert-danger">{error}</div>}

            {/* Conventional Login Form */}
            <div className="text-left my-3 p-3 mt-5 border rounded shadow-md mx-auto" style={{ width: '90%' }}>

            <form onSubmit={handleSubmit}>
              <p className="text-muted mt-5">Or Sign in with Your Email</p>

              <div className="mb-3">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  placeholder="Enter your username"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    id="password"
                    name="password"
                    value={formData.password}
                    placeholder="Enter your password"
                    onChange={handleInputChange}
                  />
                  <span
                    className="input-group-text"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: 'pointer' }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between w-100 mb-2">
                <button type="submit" className="btn btn-primary">
                  {loading ? 'Signing In...' : 'Sign In'}
                </button>
                <Link to="/forgot-password" className="text-decoration-none">
                  Forgot password?
                </Link>
              </div>
            </form>
            
    </div>


    <div className="text-left my-3 p-3 mt-4 mb-4 small text-muted border rounded shadow-md mx-auto" style={{ width: '90%' }}>

    By continuing past this page, you agree to the&nbsp;
    <Link
    to="/TermsofUse"
    className="text-decoration-none"
    style={{ marginLeft: '0.2rem', marginRight: '0.2rem', letterSpacing: '0.4px' }}
    target="_blank" // Opens in a new tab
    rel="noopener noreferrer" // Ensures security for links opened in new tabs
    >
    Terms of Use
    </Link>
    and understand that information will be used as described in
    our Privacy Policy.
    </div>
    
    <div className="text-left my-3 p-3 mt-1 mb-4 small text-muted mx-auto" style={{ width: '90%' }}>
    <h6>
    New to EventTop? <Link to="/registration-form">Sign Up</Link>
    </h6>
    </div>
    

          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;