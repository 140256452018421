
//OrganizedEventTable.js
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Table, Button, Modal, InputGroup, FormControl, Dropdown, ButtonGroup } from 'react-bootstrap';
import * as XLSX from 'xlsx'; // Import XLSX library for Excel export
import SendComplimentaryTicket from './SendComplimentaryTicket'; // Import the new component
import { API_ENDPOINTS } from '../../config';
import { useLocation, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaTrash, FaEdit, FaMapMarkedAlt, FaChartBar, FaTicketAlt, FaCog, FaTasks } from 'react-icons/fa'; // Import FontAwesome icons
//import TicketManager from './TicketManager';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';

const OrganizedEventsTable = ({ organizedEvents, handleEditEvent, handleDeleteEvent, handleTicketManager, handleSeatMapManager }) => {
  const [purchasedTickets, setPurchasedTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [summary, setSummary] = useState({
    totalPurchases: 0,
    totalAmountPaid: 0,
  });
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 767);
  const navigate = useNavigate();  
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 4;
  
  
  // Calculate the paginated events
  const reversedEvents = [...organizedEvents].reverse(); // Reverse the array
  const paginatedEvents = reversedEvents.slice(
    (currentPage - 1) * eventsPerPage,
    currentPage * eventsPerPage
  );
  
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 767);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  const groupTicketsByCustomer = (tickets) => {
    const grouped = {};
    tickets.forEach(ticket => {
      const key = `${ticket.FirstName} ${ticket.LastName} (${ticket.Email})`;
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(ticket);
    });
    return grouped;
  };
  
  const fetchPurchasedTickets = async (eventId) => {
    setLoading(true);
    setError(null);
    
    console.log('calling fetchPurchasedTickets',eventId)
    
    try {
      const response = await fetch(API_ENDPOINTS.getEventPurchasedTickets(eventId));
      if (!response.ok) {
        throw new Error('Failed to fetch purchased tickets');
      }
      const data = await response.json();

      console.log('data',data)
      
      if (data.purchasedTickets && data.purchasedTickets.length > 0) {
        const groupedTickets = groupTicketsByCustomer(data.purchasedTickets);
        setPurchasedTickets(groupedTickets);
        calculateSummary(data.purchasedTickets);
      } else {
        setPurchasedTickets({});
        setError('No ticket purchases have been made for this event yet.');
      }
    } catch (error) {
      setError(error.message || 'An error occurred while fetching purchased tickets.');
    } finally {
      setLoading(false);
    }
  };
    
  const handleSendComplimentaryTicket = async (eventId) => {
    try {
      const response = await fetch(API_ENDPOINTS.sendComplimentaryTicket(eventId), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ /* Optional data for the ticket */ }),
      });
      if (response.ok) {
        alert('Complimentary ticket sent successfully!');
      } else {
        console.error('Failed to send complimentary ticket.');
      }
    } catch (error) {
      console.error('Error sending complimentary ticket:', error);
    }
  };

  const calculateSummary = (tickets, totalCapacity = 0) => {
    const totalPurchases = tickets.length;
    const totalAmountPaid = tickets.reduce((sum, ticket) => sum + parseFloat(ticket.TotalAmount), 0);
    const totalTicketsSold = tickets.reduce((sum, ticket) => sum + parseInt(ticket.Quantity, 10), 0);
    const averageTicketPrice = totalTicketsSold > 0 ? (totalAmountPaid / totalTicketsSold).toFixed(2) : 0;
    
    console.log('calculateSummary - tickets',tickets)
    
    const highestPurchase = Math.max(...tickets.map(ticket => parseFloat(ticket.TotalAmount)));
    const lowestPurchase = Math.min(...tickets.map(ticket => parseFloat(ticket.TotalAmount)));
    
    const ticketTypeCounts = tickets.reduce((counts, ticket) => {
      counts[ticket.TicketType] = (counts[ticket.TicketType] || 0) + ticket.Quantity;
      return counts;
    }, {});
    
    const mostPopularType = Object.keys(ticketTypeCounts).reduce((a, b) => 
      ticketTypeCounts[a] > ticketTypeCounts[b] ? a : b
      , null); // Add null as default to handle empty tickets

    const percentageSold = totalCapacity > 0 && totalTicketsSold > 0
    ? ((totalTicketsSold / totalCapacity) * 100).toFixed(2)
    : 0; // Default to 0 if no tickets sold or capacity is 0
    
    setSummary({
      totalPurchases,
      totalAmountPaid: totalAmountPaid.toFixed(2),
      totalTicketsSold,
      averageTicketPrice,
      highestPurchase,
      lowestPurchase,
      mostPopularType,
      percentageSold,
    });
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value.toLowerCase());
  };

  const printTickets = () => {
    window.print();
  };

  const downloadAsCSV = () => {
    console.log('Purchased Tickets before CSV Export:', purchasedTickets);
    
    const flattenedTickets = Object.values(purchasedTickets).flat();
    console.log('Flattened Tickets for CSV:', flattenedTickets);
    
    if (flattenedTickets.length === 0) {
      alert('No tickets available to download.');
      return;
    }
    
    const csvRows = [
      ['First Name', 'Last Name', 'Email', 'Phone Number', 'Ticket Type', 'Quantity', 'Total Amount'],
      ...flattenedTickets.map(ticket => [
        ticket.FirstName || 'N/A',
        ticket.LastName || 'N/A',
        ticket.Email || 'N/A',
        ticket.PhoneNumber || 'N/A',
        ticket.TicketType || 'N/A',
        ticket.Quantity || 0,
        ticket.TotalAmount || 0
      ]),
    ];
    
    const csvContent = `data:text/csv;charset=utf-8,${csvRows.map(e => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'purchased_tickets.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAsExcel = () => {
    console.log('Purchased Tickets before Excel Export:', purchasedTickets);
    
    const flattenedTickets = Object.values(purchasedTickets).flat();
    console.log('Flattened Tickets for Excel:', flattenedTickets);
    
    if (flattenedTickets.length === 0) {
      alert('No tickets available to download.');
      return;
    }
    
    const worksheet = XLSX.utils.json_to_sheet(flattenedTickets.map(ticket => ({
      'First Name': ticket.FirstName || 'N/A',
      'Last Name': ticket.LastName || 'N/A',
      'Email': ticket.Email || 'N/A',
      'Phone Number': ticket.PhoneNumber || 'N/A',
      'Ticket Type': ticket.TicketType || 'N/A',
      'Quantity': ticket.Quantity || 0,
      'Total Amount': ticket.TotalAmount || 0,
    })));
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Purchased Tickets');
    XLSX.writeFile(workbook, 'purchased_tickets.xlsx');
  };

//const filteredTickets = purchasedTickets.filter(ticket =>
//  ticket.FirstName.toLowerCase().includes(filter) || 
//  ticket.LastName.toLowerCase().includes(filter) || 
//  ticket.Email.toLowerCase().includes(filter) || 
//  ticket.PhoneNumber.toLowerCase().includes(filter)
//);
  
  //console.log('Grouped Tickets:', purchasedTickets);
  
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  
  const handleShowDeleteModal = (event) => {
    setSelectedEvent(event);
    setShowDeleteModal(true);
  };
  
  const handleCloseDeleteModal = () => {
    setSelectedEvent(null);
    setShowDeleteModal(false);
  };


  return (
    
    
    <div
    className="table-responsive"
    style={{
      padding: isSmallScreen ? '5px' : '15px',
      margin: '0 auto',
      maxWidth: isSmallScreen ? '100%' : '95%',
    }}
    >    
    <Table
    striped
    bordered
    hover
    className="table-fluid mb-4"
    style={{
      fontSize: isSmallScreen ? '12px' : '14px',
      width: '100%',
      tableLayout: isSmallScreen ? 'fixed' : 'auto',
    }}
    >
    <thead className="bg-light table-active">
    <tr>
    <th>Event Name</th>
    {!isSmallScreen && <th>City</th>}
    {!isSmallScreen && <th>Venue</th>}
    {!isSmallScreen && <th>Date</th>}
    <th>Actions</th>
    </tr>
    </thead>
    <tbody>

    {paginatedEvents.map((event, index) => {
      const eventDate = new Date(event.EventDate);
      const formattedDate = eventDate.toLocaleDateString('en-US', { dateStyle: 'short' });      
      
      return (
        <tr key={event.id}>
        <td className="text-truncate" style={{ maxWidth: '150px' }}>{event.EventName}</td>
        {!isSmallScreen && <td>{event.EventCity}</td>}
        {!isSmallScreen && <td className="text-truncate" style={{ maxWidth: '150px' }}>{event.VenueName}</td>}
        {!isSmallScreen && <td>{formattedDate}</td>}
        <td>
        
        
        <Dropdown as={ButtonGroup} className="action-dropdown">
        {/* Dropdown Toggle Button */}
        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
        <FaCog className="me-2" /> Event Actions
        </Dropdown.Toggle>
        
        {/* Dropdown Menu */}
        <Dropdown.Menu className="custom-dropdown-menu">
        {/* Dropdown Header */}
        <Dropdown.Header className="bg-light text-secondary fw-bold">Event Actions</Dropdown.Header>
        
        {/* Dropdown Items */}
        <Dropdown.Item onClick={() => handleEditEvent(event)} className="d-flex align-items-center">
        <FaEdit className="me-2 text-primary" /> Edit Event
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleShowDeleteModal(event)} className="d-flex align-items-center">
        <FaTrash className="me-2 text-danger" /> Delete Event
        </Dropdown.Item>        
        <Dropdown.Item onClick={() => handleTicketManager(event)} className="d-flex align-items-center">
        <FaMapMarkedAlt className="me-2 text-primary" /> Ticket Manager
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleSeatMapManager(event.EventID)} className="d-flex align-items-center">
        <FaMapMarkedAlt className="me-2 text-success" /> Map Manager
        </Dropdown.Item>
        <Dropdown.Item onClick={() => fetchPurchasedTickets(event.EventID)} className="d-flex align-items-center">
        <FaChartBar className="me-2 text-info" /> Sales Report
        </Dropdown.Item>
        
        {/* Optional: Custom Component in Dropdown */}
        <Dropdown.Item as="div">
        <SendComplimentaryTicket eventId={event.EventID} />
        </Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        
        </td>
        </tr>
      );
    })}
    </tbody>
    
    
    {/* Delete Confirmation Modal */}
    <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
    <Modal.Header closeButton>
    <Modal.Title>Cannot Delete Event</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    To delete the event <strong>{selectedEvent?.EventName}</strong>, please contact our support team at{' '}
    <a href="mailto:info@eventtop.ca" style={{ color: '#4285f4', textDecoration: 'none' }}>
    info@eventtop.ca
    </a>.
    </Modal.Body>
    <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseDeleteModal}>
    Close
    </Button>
    </Modal.Footer>
    </Modal>
    
    </Table>
    
    
    
    <div className="d-flex justify-content-center align-items-center my-2 mb-2 gap-3">
    <FaArrowCircleLeft
    className={`text-primary ${currentPage === 1 ? 'text-muted' : 'cursor-pointer'}`}
    style={{ fontSize: '1.5rem' }}
    onClick={() => currentPage > 1 && setCurrentPage((prev) => prev - 1)}
    />
    <span className="text-muted small">
    <strong>{currentPage}</strong> / <strong>{Math.ceil(organizedEvents.length / eventsPerPage)}</strong>
    </span>
    <FaArrowCircleRight
    className={`text-primary ${currentPage === Math.ceil(organizedEvents.length / eventsPerPage) ? 'text-muted' : 'cursor-pointer'}`}
    style={{ fontSize: '1.5rem' }}
    onClick={() =>
      currentPage < Math.ceil(organizedEvents.length / eventsPerPage) && setCurrentPage((prev) => prev + 1)
    }
    />
    </div>
    
    
    
    
    <div className="mb-3">
    <div className="d-flex flex-wrap justify-content-between align-items-center gap-3">
    {/* Filter Input */}
    <InputGroup className="flex-grow-1" style={{ maxWidth: '500px' }}>
    <InputGroup.Text>Filter</InputGroup.Text>
    <FormControl
    placeholder="Search by name, email, or phone"
    onChange={handleFilterChange}
    />
    </InputGroup>
    
    {/* Buttons */}
    <div className="d-flex flex-wrap gap-2 justify-content-md-end">
    <Button variant="outline-secondary" onClick={printTickets} className="btn-sm">
    <i className="fas fa-print"></i> Print
    </Button>
    <Button variant="outline-success" onClick={downloadAsCSV} className="btn-sm">
    <i className="fas fa-file-csv"></i> CSV
    </Button>
    <Button variant="outline-primary" onClick={downloadAsExcel} className="btn-sm">
    <i className="fas fa-file-excel"></i> Excel
    </Button>
    </div>
    </div>
    </div>
    
    
    {/* Summary Section with Card Layout */}
    <Row className="mb-4 g-2 justify-content-center">
    {[
      { title: 'Total Purchases', value: summary.totalPurchases, icon: '🛒' },
      { title: 'Total Tickets Sold', value: summary.totalTicketsSold, icon: '🎟️' },
      { title: 'Total Paid', value: `CA$ ${summary.totalAmountPaid}`, icon: '💰' },
      { title: 'Average Ticket Price', value: `CA$ ${summary.averageTicketPrice}`, icon: '📈' },
      { title: 'Most Popular Ticket Type', value: summary.mostPopularType, icon: '⭐' },
      { title: 'Percentage Sold', value: `${summary.percentageSold}%`, icon: '📊' },
    ].map((item, index) => (
      <Col key={index} lg={2} md={4} sm={6} xs={12}>
      <Card
      className="h-100 shadow-sm"
      style={{
        background: index % 2 === 0 ? '#f8f9fa' : '#e9ecef',
        border: 'none',
        borderRadius: '12px',
      }}
      >
      <Card.Body className="d-flex flex-column justify-content-center align-items-center">
      <div
      style={{
        fontSize: '2.0rem',
        marginBottom: '0.5rem',
        color: '#495057',
      }}
      >
      {item.icon}
      </div>
      <Card.Title className="mb-2 text-center" style={{ fontSize: '0.75rem', fontWeight: '600', color: '#6c757d' }}>
      {item.title}
      </Card.Title>
      <Card.Text className="text-center" style={{ fontSize: '1.0rem', fontWeight: '0', color: '#343a40' }}>
      {item.value}
      </Card.Text>
      </Card.Body>
      </Card>
      </Col>
    ))}
    </Row>
    
    {loading ? (
      <p>Loading...</p>
    ) : error ? (
      <p className="text-danger">{error}</p>
    ) : Object.keys(purchasedTickets).length > 0 ? (

      <Table
      striped
      bordered
      hover
      className="table-fluid mb-4"
      style={{
        fontSize: isSmallScreen ? '12px' : '14px',
        width: '100%',
        tableLayout: isSmallScreen ? 'fixed' : 'auto',
      }}
      >
      <thead>
      <tr>
      <th style={{ padding: isSmallScreen ? '5px' : '10px' }}>Customer Name</th>
      {!isSmallScreen && <th style={{ padding: isSmallScreen ? '5px' : '10px' }}>Email</th>}
      {!isSmallScreen && <th style={{ padding: isSmallScreen ? '5px' : '10px' }}>Phone Number</th>}
      {!isSmallScreen && <th style={{ padding: isSmallScreen ? '5px' : '10px' }}>Tickets</th>}
      <th style={{ padding: isSmallScreen ? '5px' : '10px' }}>Total Paid Amount</th>
      </tr>
      </thead>
      <tbody>
      {Object.entries(purchasedTickets).map(([customerKey, tickets], index) => {
        // Extract customer name and calculate total paid amount
        const customerName = customerKey.split('(')[0].trim();
        const totalPaid = tickets.reduce((sum, ticket) => sum + parseFloat(ticket.TotalAmount), 0);
        
        return (
          <tr key={index}>
          {/* Customer Name */}
          <td style={{ padding: isSmallScreen ? '5px' : '10px' }}>{customerName}</td>
          
          {/* Email */}
          {!isSmallScreen && (
            <td style={{ padding: isSmallScreen ? '5px' : '10px' }}>
            {tickets[0]?.Email || 'N/A'}
            </td>
          )}
          
          {/* Phone Number */}
          {!isSmallScreen && (
            <td style={{ padding: isSmallScreen ? '5px' : '10px' }}>
            {tickets[0]?.PhoneNumber || 'N/A'}
            </td>
          )}
          
          {/* Tickets */}
          {!isSmallScreen && (
            <td
            style={{
              maxWidth: isSmallScreen ? '100px' : '150px',
              padding: isSmallScreen ? '5px' : '10px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            >
            {tickets.map((ticket, idx) => `${ticket.TicketType}: ${ticket.Quantity}`).join(', ')}
            </td>
          )}
          
          {/* Total Paid Amount */}
          <td style={{ padding: isSmallScreen ? '5px' : '10px' }}>
          CA$ {totalPaid.toFixed(2)}
          </td>
          </tr>
        );
      })}
      </tbody>
      </Table>

    ) : (
      <p>No purchased tickets found for this event.</p>
    )}

    </div>
  );
};

export default OrganizedEventsTable;