import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Row, Col, Button } from 'react-bootstrap';
import { FaPlus, FaTrash } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';

const TicketManager = ({ event, userData, fetchTicketData, onClose }) => {
  const [tickets, setTickets] = useState([]);

  // Load tickets for the provided event
  useEffect(() => {
    const loadTicketsForEvent = async () => {
      try {
        console.log('Fetching tickets for event:', event); // Debug: log the event object
        console.log('EventID:', event?.EventID); // Debug: log the EventID
        
        const response = await fetchTicketData(event.EventID);
        
        console.log('Raw ticket data received:', response); // Debug: log the raw response data
        
        const sanitizedTickets = response.map((ticket) => ({
          ...ticket,
          Price: ticket.Price || 0,
          Availability: ticket.Availability || 0,
        }));
        
        console.log('Sanitized tickets:', sanitizedTickets); // Debug: log sanitized tickets
        
        setTickets(sanitizedTickets);
      } catch (error) {
        console.error('Error loading tickets:', error); // Debug: log errors
        setTickets([]);
      }
    };
    
    console.log('Event object before ticket fetch:', event); // Debug: log event object before fetching
    if (event && event.EventID) {
      console.log('Starting ticket fetch for EventID:', event.EventID); // Debug: log the beginning of fetch
      loadTicketsForEvent();
    } else {
      console.warn('Invalid event or EventID missing:', event); // Debug: log invalid event
    }
    
    console.log('Tickets state after fetch attempt:', tickets); // Debug: log the current tickets state
  }, [event, fetchTicketData]);
  
  
  const sanitizeDate = (date) => (date && !isNaN(new Date(date)) ? new Date(date) : null);

  const handleTicketChange = (index, e) => {
    const { name, value } = e.target;
    setTickets((prevTickets) =>
      prevTickets.map((ticket, idx) =>
        idx === index ? { ...ticket, [name]: value } : ticket
      )
    );
  };

  const handleDateChange = (index, field, date) => {
    setTickets((prevTickets) =>
      prevTickets.map((ticket, idx) =>
        idx === index ? { ...ticket, [field]: sanitizeDate(date) } : ticket
      )
    );
  };

  const handleServiceFeeChange = (index, e) => {
    setTickets((prevTickets) =>
      prevTickets.map((ticket, idx) =>
        idx === index ? { ...ticket, AbsorbServiceFee: e.target.checked } : ticket
      )
    );
  };

  const addTicket = () => {
    setTickets((prevTickets) => [
      ...prevTickets,
      {
        TicketType: '',
        Price: 0,
        Availability: 0,
        PurchaseStartDate: new Date(),
        PurchaseDeadline: new Date(),
        AbsorbServiceFee: false,
        TicketDetails: '',
        Notes: '13% HST will be added at checkout.',
      },
    ]);
  };

  const removeTicket = (index) => {
    setTickets((prevTickets) => prevTickets.filter((_, idx) => idx !== index));
  };

  return (
    <div className="form-group mt-4 mb-4">
      <h5 className="text-primary fw-bold">
        Ticket Management {event?.EventName ? `for ${event.EventName}` : ''}
      </h5>

      {tickets.length === 0 && <p>No tickets available. Use the button below to create a new ticket.</p>}

    {tickets.map((ticket, index) => (
      <div key={index} className="ticket-info">
      <Row className="align-items-end mb-4">
      <Col md={3} lg={2} xs={12} className="mb-3">
      <label>
      Ticket Type: <span style={{ color: 'red' }}>*</span>
      </label>
      <input
      type="text"
      className="form-control"
      name="TicketType"
      value={ticket.TicketType || ''}
      onChange={(e) => handleTicketChange(index, e)}
      required
      />
      </Col>
      
      <Col md={3} lg={2} xs={12} className="mb-3">
      <label>Price:</label>
      <input
      type="number"
      className="form-control"
      name="Price"
      value={ticket.Price || ''}
      onChange={(e) => handleTicketChange(index, e)}
      required
      />
      </Col>
      
      <Col md={3} lg={2} xs={12} className="mb-3">
      <label>Availability:</label>
      <input
      type="number"
      className="form-control"
      name="Availability"
      value={ticket.Availability || ''}
      onChange={(e) => handleTicketChange(index, e)}
      required
      />
      </Col>
      
      <Col md={3} lg={2} xs={12} className="mb-3">
      <label>Purchase Start Date:</label>
      <DatePicker
      selected={ticket.PurchaseStartDate || null}
      onChange={(date) => handleDateChange(index, 'PurchaseStartDate', date)}
      className="form-control"
      />
      </Col>
      
      <Col md={3} lg={2} xs={12} className="mb-3">
      <label>Purchase Deadline:</label>
      <DatePicker
      selected={ticket.PurchaseDeadline || null}
      onChange={(date) => handleDateChange(index, 'PurchaseDeadline', date)}
      className="form-control"
      />
      </Col>
      
      <Col md={3} lg={2} xs={12} className="mb-3">
      <label>
      <input
      type="checkbox"
      checked={!!ticket.AbsorbServiceFee}
      onChange={(e) => handleServiceFeeChange(index, e)}
      />{' '}
      Absorb Service Fee
      </label>
      </Col>
      
      {tickets.length > 1 && (
        <Col md={3} xs={12} className="text-center mb-3 mt-3">
        <Button variant="danger" onClick={() => removeTicket(index)}>
        <FaTrash /> Remove
        </Button>
        </Col>
      )}
      </Row>
      </div>
    ))}

      <div className="d-flex justify-content-between mt-4">
        <Button variant="success" onClick={addTicket}>
          <FaPlus /> Add Ticket
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Close Ticket Manager
        </Button>
      </div>
    </div>
  );
};

export default TicketManager;