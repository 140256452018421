// UserPanel.js
import React, { useEffect, useState, useCallback, useMemo } from 'react'; 
import { useNavigate } from 'react-router-dom';
import { fetchWithAuth } from '../react-auth-app/auth';
import { Container, Row, Col, Card } from 'react-bootstrap';
import CreateEvent from '../create-event/CreateEvent';
import UserInfo from '../react-auth-app/userInfo';
import TicketsTable from '../react-auth-app/TicketsTable';
import OrganizedEventsTable from '../react-auth-app/components/OrganizedEventsTable';
import BanquetHallLayout from '../ticket-store/utils/BanquetHallLayout';
import LegendDisplay from '../ticket-store/utils/Legend';
import ControlPanelButtons from './components/ControlPanelButtons';
import TicketManager from './components/TicketManager';

import { API_ENDPOINTS } from '../config';
import './UserPanel.css';
import { FaInfoCircle, FaCalendarPlus, FaTicketAlt, FaTasks, FaSignOutAlt } from 'react-icons/fa';

const UserPanel = () => {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [organizedEvents, setOrganizedEvents] = useState([]);
  const [userData, setUserData] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);
  const [editingTickets, setEditingTickets] = useState(null);
  const [showCreateEventForm, setShowCreateEventForm] = useState(false);
  const [showOrganizedEvents, setShowOrganizedEvents] = useState(false);
  const [showTicketManager, setShowTicketManager] = useState(false);
  const [selectedEventLocation, setSelectedEventLocation] = useState(null); // State for selected event location
  const [tableOptions, setTableOptions] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState({});
  const [showSeatMap, setShowSeatMap] = useState(false);
  const [hasMap, setHasMap] = useState(false);  
  //const eventId = editingEvent?.EventID;
  
  // Sanitize a date value to ensure it's valid
  const sanitizeDate = (date) => {
    return date && !isNaN(new Date(date)) ? new Date(date) : null;
  };


  const fetchUserData = useCallback(async (userId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.getUser(userId));
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
      navigate('/sign-in');
    }
  }, [navigate]);

  const fetchTickets = useCallback(async (userId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.getTickets(userId));
      if (!response.ok) {
        if (response.status === 404) {
          console.warn('No tickets found for this user');
          return []; // Return an empty array when no tickets are found
        }
        throw new Error('Failed to fetch tickets');
      }
      const data = await response.json();
      return data; // Explicitly return the fetched data
    } catch (error) {
      console.error('Error fetching tickets:', error);
      return []; // Return an empty array on error
    }
  }, []);
  
  // Fetch ticket data for a specific event
  const fetchTicketData = async (eventId) => {
    try {
      const response = await fetch(API_ENDPOINTS.getEventTickets(eventId));
      if (!response.ok) throw new Error('Failed to fetch ticket data');
      const data = await response.json();
      const sanitizedTickets = (data.tickets || []).map(ticket => ({
        ...ticket,
        PurchaseStartDate: sanitizeDate(ticket.PurchaseStartDate),
        PurchaseDeadline: sanitizeDate(ticket.PurchaseDeadline),
      }));
      setTickets(sanitizedTickets);
    } catch (error) {
      console.error('Error fetching ticket data:', error);
      setTickets([]); // Fallback to empty array
    }
  };

  const fetchOrganizedEvents = useCallback(async (userId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.organizedEvents(userId));
      const data = await response.json();
      setOrganizedEvents(data);
    } catch (error) {
      console.error('Error fetching organized events:', error);
    }
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const accessToken = localStorage.getItem('accessToken');
    
    if (userId && accessToken) {
      fetchUserData(userId); // Fetch user data only once
    } else {
      navigate('/sign-in'); // Redirect to sign-in if not authenticated
    }
  }, [fetchUserData, navigate]);
  
  // Separate useEffect for tickets and organized events
  useEffect(() => {
    if (userData) {
      // Only fetch tickets and events once userData is loaded
      fetchTickets(userData.id);
      fetchOrganizedEvents(userData.id);
    }
  }, [userData, fetchTickets, fetchOrganizedEvents]);
  
  const handleCreateEvent = () => {
    setShowCreateEventForm(true);
    setShowOrganizedEvents(false);
    setShowSeatMap(false); // Reset seat map visibility
    setHasMap(false); // Reset map status
    setEditingEvent(null);
    setShowTicketManager(false); // Show the ticket manager
  };

  const handleShowPurchases = () => {
    setShowCreateEventForm(false);
    setShowOrganizedEvents(false);
    setShowSeatMap(false); // Reset seat map visibility
    setHasMap(false); // Reset map status
    setShowTicketManager(false); // Show the ticket manager
  };

  const handleShowOrganizedEvents = () => {
    setShowCreateEventForm(false);
    setShowOrganizedEvents(true);
    setShowSeatMap(false); // Reset seat map visibility
    setHasMap(false); // Reset map status
    setShowTicketManager(false); // Show the ticket manager
  };

  const handleEditEvent = (event) => {
    setEditingEvent(event); // Set the selected event as the event to edit
    setShowCreateEventForm(true); // Show the form for editing
    setShowOrganizedEvents(false);
    setShowSeatMap(false); // Reset seat map visibility
    setHasMap(false); // Reset map status
    setShowTicketManager(false); // Show the ticket manager
  };

  const handleDeleteEvent = async (eventId) => {
    //console.log('eventID is:',event.EventID)
    //console.log('API_ENDPOINTS.deleteEvent(eventId) is:',API_ENDPOINTS.deleteEvent(eventId))
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        const response = await fetch(API_ENDPOINTS.deleteEvent(eventId), {
          method: 'DELETE',
        });
        if (response.ok) {
          alert('Event deleted successfully!');
          if (userData) fetchOrganizedEvents(userData.id);
        } else {
          console.error('Failed to delete event.');
        }
      } catch (error) {
        console.error('Error deleting event:', error);
      }
    }
  };
  
  
  const handleTicketManager = (event) => {
    setEditingEvent(event); // Set the selected event for editing
    setTickets(event.tickets || []); // Initialize with event tickets (if available)
    setShowCreateEventForm(false); // Ensure the create event form is hidden
    setShowOrganizedEvents(false); // Ensure organized events view is hidden
    setShowSeatMap(false); // Reset seat map visibility
    setHasMap(false); // Reset map status
    setShowTicketManager(true); // Show the ticket manager
  };
  

  
  const handleSeatSelection = (selection) => {
    const { tableId, tableNumber, tableType } = selection;
    
    //console.log('Seat Selection Input:', selection); // Debugging log
    
    // Ensure all required fields are present
    if (!tableId || tableNumber <= 0) {
      console.error('Invalid selection:', selection);
      return;
    }
    
    //console.log('totalSeats',totalSeats)
    
    setSelectedSeats((prevSeats) => {
      const updatedSeats = {
        ...prevSeats,
        [tableId]: {
          tableId,
          tableNumber,
          tableType
        },
      };
      //console.log('Updated SelectedSeats:', updatedSeats); // Debugging log
      return updatedSeats;
    });
  };

  const handleSeatMapManager = async (eventId) => {
    try {
      const response = await fetchWithAuth(API_ENDPOINTS.getEventTables(eventId));
      if (response.ok) {
        const tableData = await response.json();
        
        if (tableData && tableData.tables && tableData.tables.length > 0) {
          setTableOptions(tableData.tables);
          setHasMap(true); // Seat map is available
          setShowTicketManager(false); // Show the ticket manager
          setShowSeatMap(true); // Show the BanquetHallLayout
        } else {
          console.warn('No map data exists for this event.');
          setTableOptions([]);
          setHasMap(false); // No seat map available
          setShowTicketManager(false); // Show the ticket manager
          setShowSeatMap(true); // Still open the map container to show the placeholder
        }
      } else {
        console.error(`Failed to fetch seat map. Status code: ${response.status}`);
        alert('No map data exists for this event. If you need a map for you event contact "info@eventttop.ca".');
      }
    } catch (error) {
      console.error('Error loading seat map:', error);
      alert('An error occurred while fetching the seat map.');
    }
  };

  // Memoized legend items
  const legendItems = useMemo(() => {
    return [
      ...new Map(
        tableOptions
        .filter(
          (item) =>
          item.PricePerSeat !== null &&
          item.PricePerSeat !== undefined &&
          item.AvailableSeats > 0 &&
          item.ElementType !== 'Dance Floor' &&
          item.ElementType !== 'Stage'
        )
        .map((item) => [
          `${item.TableType || item.ElementType}-${item.ElementColor || ''}-${item.PricePerSeat}`,
          {
            type: item.TableType || item.ElementType,
            color:
            item.ElementColor ||
            (item.TableType === 'VIP' ? '#ffd700' : '#007bff'),
            price: item.PricePerSeat,
          },
        ])
      ).values(),
    ];
  }, [tableOptions]);

  const closeSeatMap = () => {
    setShowSeatMap(false);
    setHasMap(false);
    setTableOptions([]); // Clear table data
  };
    
  const handleSignOut = () => {
    setUserData(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    document.cookie = 'refreshToken=; Max-Age=0; path=/; domain=' + window.location.hostname;
    navigate('/sign-in');
    window.location.reload(); // Force a page reload to clear any lingering state
  };

  const handleEventCreated = () => {
    if (userData) fetchOrganizedEvents(userData.id);
    setShowCreateEventForm(false);
  };
  
  const handleSendComplimentaryTicket = async (eventId) => {
    try {
      const response = await fetch(API_ENDPOINTS.sendComplimentaryTicket(eventId), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ /* Ticket details here */ }),
      });
      if (response.ok) {
        alert('Complimentary ticket sent successfully!');
      } else {
        console.error('Failed to send complimentary ticket.');
      }
    } catch (error) {
      console.error('Error sending complimentary ticket:', error);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className="shadow-lg p-2">
            <Card.Body>
              <h2 className="card-title text-center">User Panel</h2>
              {userData && <UserInfo userData={userData} />}
              
    {/* Add enhanced instructions here */}
    <div className="alert alert-info text-center mb-4 shadow-sm border border-primary rounded">
    <h5 className="mb-4">
    <FaInfoCircle className="me-2 text-primary" size={24} /> <strong>Control Panel Instructions</strong>
    </h5>
    <div className="row g-3">
    <div className="col-md-3 col-sm-6 text-center">
    <FaCalendarPlus className="text-primary mb-2" size={40} />
    <p>
    <strong>New Event:</strong> <br />
    Create and publish a new event for your audience.
    </p>
    </div>
    <div className="col-md-3 col-sm-6 text-center">
    <FaTicketAlt className="text-info mb-2" size={40} />
    <p>
    <strong>Past Tickets:</strong> <br />
    View all the tickets you have purchased for past events.
    </p>
    </div>
    <div className="col-md-3 col-sm-6 text-center">
    <FaTasks className="text-dark mb-2" size={40} />
    <p>
    <strong>Event Dashboard:</strong> <br />
    Manage and review events you have organized.
    </p>
    </div>
    <div className="col-md-3 col-sm-6 text-center">
    <FaSignOutAlt className="text-warning mb-2" size={40} />
    <p>
    <strong>Sign Out:</strong> <br />
    Safely log out from your account.
    </p>
    </div>
    </div>
    </div>
              
    
    <ControlPanelButtons
      handleCreateEvent={handleCreateEvent}
      handleShowPurchases={handleShowPurchases}
      handleShowOrganizedEvents={handleShowOrganizedEvents}
      handleSignOut={handleSignOut}
    />    

    

    {showCreateEventForm && (
      <CreateEvent userData={userData} onEventCreated={handleEventCreated} editingEvent={editingEvent} />
    )}
    
    {showOrganizedEvents && !showSeatMap && (
      <OrganizedEventsTable
      organizedEvents={organizedEvents}
      handleEditEvent={handleEditEvent}
      handleDeleteEvent={handleDeleteEvent}
      handleTicketManager={handleTicketManager}
      handleSeatMapManager={handleSeatMapManager}
      />
    )}
    

    
    {!showCreateEventForm && !showOrganizedEvents && !showSeatMap && tickets.length === 0 && (
      <div className="text-center text-muted mt-3">
      <h5>No tickets have been purchased yet.</h5>
      </div>
    )}
    
    {/* Other JSX content */}
    {showTicketManager && (
      <TicketManager
      event={editingEvent} // Pass the currently selected event
      userData={userData}
      fetchTicketData={fetchTicketData} // Function to fetch tickets
      onClose={() => setShowTicketManager(false)} // Allow closing the TicketManager
      />
    )}

    {showSeatMap ? (
      hasMap ? (
        <>
        {/* Legend */}
        <LegendDisplay legendItems={legendItems} />
        <BanquetHallLayout tableOptions={tableOptions} handleSeatSelection={handleSeatSelection} />
        </>
      ) : (
        <div className="placeholder-container">
        <div className="placeholder-icon">!</div>
        <h3>Oops! Something went wrong.</h3>
        <p>This event does not have a seat map available. Please check back later or contact the organizer for more details.</p>
        </div>
      )
    ) : null}
    
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserPanel;