import React from 'react';
import { Button } from 'react-bootstrap';
import { FaCalendarPlus, FaTicketAlt, FaTasks, FaSignOutAlt } from 'react-icons/fa';

const ControlPanelButtons = ({ handleCreateEvent, handleShowPurchases, handleShowOrganizedEvents, handleSignOut }) => (
  
  <div className="row g-3 text-center mb-3">
    <div className="col-12 col-sm-6 col-md-3">
      <Button variant="outline-primary" className="w-100" onClick={handleCreateEvent}>
        <FaCalendarPlus className="me-2" /> New Event
      </Button>
    </div>
    <div className="col-12 col-sm-6 col-md-3">
      <Button variant="outline-info" className="w-100" onClick={handleShowPurchases}>
        <FaTicketAlt className="me-2" /> Past Tickets
      </Button>
    </div>
    <div className="col-12 col-sm-6 col-md-3">
      <Button variant="outline-dark" className="w-100" onClick={handleShowOrganizedEvents}>
        <FaTasks className="me-2" /> Event Dashboard
      </Button>
    </div>
    <div className="col-12 col-sm-6 col-md-3">
      <Button variant="outline-warning" className="w-100" onClick={handleSignOut}>
        <FaSignOutAlt className="me-2" /> Sign Out
      </Button>
    </div>
  </div>
);

export default ControlPanelButtons;