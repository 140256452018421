// TicketStorePage.js

import React from 'react';
import { Container, Row, Button, Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../config';
import { FaTicketAlt, FaCalendarAlt, FaMapMarkerAlt, FaUserTie, FaDoorOpen, FaPalette, FaAccessibleIcon, FaInfoCircle } from 'react-icons/fa';
//import SpotlightAd from '../ads/SpotlightAd'; // Import the SpotlightAd component
//import SpotlightAdOverlay from '../ads/SpotlightAdOverlay'; // Import SpotlightAdOverlay


const TicketStorePage = ({ event,tickets }) => {
  const navigate = useNavigate();
  
  //console.log('event',event)

  // Check if event is null before accessing its properties
  if (!event) {
    return <div>Loading...</div>; // Render loading state while waiting for event data
  }

  const handlePurchase = () => {
    // Logic for handling ticket purchase
    navigate('/purchase-tickets', { state: { event } });
  };
  
  // Construct the Google Maps URL with the venue address
  const mapsUrl = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAS8IoH8aEfCgmsZeS7HmEuP3M783tnipY&q=${encodeURIComponent(
    event.VenueAddress
  )}`;
  
  // Determine the event poster image path
  const posterImagePath = event.PosterImagePath
  ? event.PosterImagePath.startsWith(API_ENDPOINTS.uploads)
  ? `${API_ENDPOINTS.uploads}${event.PosterImagePath}`
  : `${API_ENDPOINTS.uploads}/${event.PosterImagePath.split('/').pop()}`
  : `${API_ENDPOINTS.uploads}/placeholder_image.png`; // Use a default placeholder image if path is missing
  
  const today = new Date();
  
  // Check if all tickets have passed their purchase deadlines
  const allTicketsExpired =
    tickets &&
    tickets.length > 0 &&
    tickets.every((ticket) => new Date(ticket.PurchaseDeadline) < today);
  
  return (
    <>

    {/* Spotlight Advertisement */}
    {/* <SpotlightAd />*/}
    <Row className="justify-content-center p-md-2 mx-auto">
    <Container className="p-2 p-md-1 mb-1 border rounded bg-light d-flex flex-column align-items-center">
    <Carousel interval={5000} pause="hover" defaultActiveIndex={0} keyboard={false} touch={false} wrap={true} className="shadow-lg w-100">
    <Carousel.Item>
    <img 
    src="/images/Turkuaz2025Banner-Coming Soon.jpg" 
    alt="Turkuaz Festival Toronto 2025" 
    className="img-fluid d-block w-100"
    style={{ maxWidth: '100%', height: 'auto' }} 
    />
    </Carousel.Item>
    <Carousel.Item>
    <img 
    src="/images/Dilmaj-banner-ad.png" 
    alt="Dilmaj Translation advertisement, Montreal" 
    className="img-fluid d-block w-100"
    style={{ maxWidth: '100%', height: 'auto' }} 
    />
    </Carousel.Item>
    <Carousel.Item>
    <img 
    src="/images/photo-mahnaz-ad.png" 
    alt="Photo Mahnaz advertisement, Montreal" 
    className="img-fluid d-block w-100"
    style={{ maxWidth: '100%', height: 'auto' }} 
    />
    </Carousel.Item>
    </Carousel>
    </Container>
    </Row>
  
    
    <Row className="justify-content-center p-md-2 mx-auto bg-shadow">

    {/* first empty row */}    
    <Container className="p-2 p-md-3 mb-0 border rounded bg-light">
      <h1 className="text-center">Event Store</h1>
      <div className="bg-light-gray p-0 rounded">
      
    {allTicketsExpired && (
      <p className="alert alert-warning text-center">
      Ticket sales for this event have ended. Thank you for your interest! 
      <br />
      Explore other exciting events on our website and find your next experience.
      </p>
    )}
      
      <p className="mb-0">
      <strong><FaTicketAlt /> Event Name:</strong> {event.EventName}
      </p>
      <p className="mb-3">
      <strong><FaUserTie /> Organizer:</strong> {event.OrganizerName}
      </p>
      <p className="mb-3">
      <strong><FaTicketAlt /> Tickets:</strong>
      {tickets && tickets.length > 0 ? (
        <ul>
        {tickets.map((ticket, index) => (
          <li key={index}>
          {ticket.TicketType} - ${ticket.Price}{' '}
          {ticket.TicketDetails && (
            <span className="text-muted">({ticket.TicketDetails})</span>
          )}
          </li>
        ))}
        </ul>
      ) : (
        ' Free Event!'
      )}
      </p>
      <p className="mb-0">
      <strong><FaCalendarAlt /> Date/Time:</strong>{' '}
      {new Date(event.EventDate).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })}, {event.StartTime} {event.EndTime ? `- ${event.EndTime}` : ''}
      </p>
      <p className="mb-0">
      <strong><FaDoorOpen /> Doors Open:</strong> {event.DoorsOpenTime}
      </p>
      <p className="mb-0">
      <strong><FaMapMarkerAlt /> Venue:</strong> {event.VenueName}, {event.VenueAddress}
      </p>
      <p className="mb-3">
      <strong><FaMapMarkerAlt /> Event City/Country:</strong> {event.EventCity}, {event.EventCountry}
      </p>
      
      <p className="mb-0">
      <strong><FaPalette /> Theme:</strong> {event.Theme}
      </p>
      <p className="mb-0">
      <strong><FaPalette /> Dress Code:</strong> {event.DressCode}
      </p>
      <p className="mb-2">
      <strong><FaAccessibleIcon /> Accessibility Info:</strong> {event.AccessibilityInfo}
      </p>
      
      <p className="mb-2 px-5" style={{ textAlign: 'justify' }}>
      <strong><FaInfoCircle /> About Event:</strong> {event.AboutEvent}
      </p>
    
      {/* Purchase button */}
      <div className="bg-light-gray p-1 rounded text-center">
        <Button
        variant="primary"
        className="mx-auto"
        onClick={handlePurchase}
        style={{ width: '80%' }}
        disabled={allTicketsExpired}
        >
        {allTicketsExpired ? 'Sales Ended' : 'Purchase Tickets'}
        </Button>
      </div>
      </div>
      </Container>
    </Row>
    
    <Row className="justify-content-center p-md-3 mx-auto">
    {/* Secondary empty row */}
    <h4 className="text-center">Event Poster</h4>
    <Container className="p-0 p-md-0 mb-0 border rounded bg-light">
    <div className="bg-light-gray p-0 rounded justify-content-center text-center">
    <img
    src={posterImagePath}
    alt="Event Poster"
    className="img-fluid mx-auto d-block rounded"
    onError={(e) => { e.target.onerror = null; e.target.src = `${API_ENDPOINTS.uploads}/placeholder_image.png`; }} // Fallback image
    />
    {/* Render additional event details here */}
    </div>
    </Container>
    </Row>
    
    {event.seatmap === 1 && (
      <Row className="justify-content-center p-md-3 mx-auto">
      {/* Seat Map row, only visible if seatmap is 1 */}
      <h4 className="text-center">Seat Map</h4>
      <Container className="p-0 p-md-0 mb-3 border rounded bg-light">
      <div className="bg-light-gray p-0 rounded">
      <img
      src="https://via.placeholder.com/350"
      alt="Seat Map"
      className="img-fluid mx-auto d-block rounded"
      />
      </div>
      </Container>
      </Row>
    )}
    
    <Row className="justify-content-center p-md-3 mx-auto">
    {/* Fourth empty row */}
    <h4 className="text-center">Venue Location</h4>
    <Container className="p-0 p-md-0 mb-3 border rounded bg-light">
    <div className="bg-light-gray p-0 rounded justify-content-center text-center">
    <iframe
    title="Google Maps"
    width="100%"
    height="400"
    loading="lazy"
    allowFullScreen
    className="rounded"
    src={mapsUrl}
    ></iframe>
    {/* Render additional event details here */}
    <p className="mt-2">
    <strong>Venue:</strong> {event.VenueName}, {event.VenueAddress}
    </p>
    <p className="mb-2 px-5" style={{ textAlign: 'justify' }}>
    <strong>About Venue:</strong> {event.VenueDetails}
    </p>
    </div>
    
    </Container>
    </Row>
    
    <Row className="justify-content-center p-md-3 mx-auto">
      {/* Fifth empty row */}
      <Container className="p-0 p-md-1 mb-0 border rounded bg-light">
      <div className="bg-light-gray p-0 rounded text-center">
      <Button
      variant="primary"
      className="mx-auto"
      onClick={handlePurchase}
      style={{ width: '80%' }}
      disabled={allTicketsExpired}
      >
      {allTicketsExpired ? 'Sales Ended' : 'Purchase Tickets'}
      </Button>
      {/* Render additional event details here */}
      </div>
      </Container>
    </Row>
    
    {/* Spotlight Advertisement */}  
    {/* <SpotlightAd /> */}
    <Row className="justify-content-center p-md-2 mx-auto">
    <Container className="p-2 p-md-1 mb-1 border rounded bg-light d-flex flex-column align-items-center">
    <Carousel interval={3000} pause="hover" className="shadow-lg w-100">
    <Carousel.Item>
    <img 
    src="/images/Turkuaz2025Banner-Coming Soon.jpg" 
    alt="Turkuaz Festival Toronto 2025" 
    className="img-fluid d-block w-100"
    style={{ maxWidth: '100%', height: 'auto' }} 
    />
    </Carousel.Item>
    <Carousel.Item>
    <img 
    src="/images/Dilmaj-banner-ad.png" 
    alt="Dilmaj Translation advertisement, Montreal" 
    className="img-fluid d-block w-100"
    style={{ maxWidth: '100%', height: 'auto' }} 
    />
    </Carousel.Item>
    <Carousel.Item>
    <img 
    src="/images/photo-mahnaz-ad.png" 
    alt="Photo Mahnaz advertisement, Montreal" 
    className="img-fluid d-block w-100"
    style={{ maxWidth: '100%', height: 'auto' }} 
    />
    </Carousel.Item>
    </Carousel>
    </Container>
    </Row>
    
    </>
  );
};

export default TicketStorePage;
  