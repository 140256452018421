import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { API_ENDPOINTS } from '../../config';

const SendComplimentaryTicket = ({ eventId }) => {
  const [showModal, setShowModal] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [ticketTypes, setTicketTypes] = useState([]);
  const [ticketQuantities, setTicketQuantities] = useState({});
  const [eventData, setEventData] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };
  
  useEffect(() => {
    if (showModal) {
      console.log('Modal opened. Fetching event and ticket types for EventID:', eventId);
      fetchEventDetails();
      fetchTicketTypes();
    }
  }, [showModal]);
  
  const fetchEventDetails = async () => {
    try {
      console.log('Calling API to fetch event details:', API_ENDPOINTS.getEvent(eventId));
      const response = await fetch(API_ENDPOINTS.getEvent(eventId));
      if (!response.ok) {
        console.error('Failed to fetch event details. HTTP Status:', response.status);
        throw new Error('Failed to fetch event details.');
      }
      const data = await response.json();
      console.log('Fetched event details:', data);
      setEventData(data);
    } catch (error) {
      console.error('Error fetching event details:', error);
    }
  };
  
  const fetchTicketTypes = async () => {
    try {
      console.log('Calling API to fetch ticket types:', API_ENDPOINTS.getEventTickets(eventId));
      const response = await fetch(API_ENDPOINTS.getEventTickets(eventId));
      if (!response.ok) {
        console.error('Failed to fetch ticket types. HTTP Status:', response.status);
        throw new Error('Failed to fetch ticket types.');
      }
      const data = await response.json();
      console.log('Fetched ticket types data:', data);
      
      if (data.tickets && data.tickets.length > 0) {
        setTicketTypes(data.tickets);
        const initialQuantities = {};
        data.tickets.forEach((type) => {
          initialQuantities[type.TicketID] = 0;
        });
        setTicketQuantities(initialQuantities);
      } else {
        setTicketTypes([]);
      }
    } catch (error) {
      console.error('Error fetching ticket types:', error);
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };
  
  const handleQuantityChange = (ticketId, quantity) => {
    const parsedQuantity = parseInt(quantity, 10);
    
    setTicketQuantities((prev) => ({
      ...prev,
      [ticketId]: quantity === '' ? '' : Math.max(0, parsedQuantity || 0), // Allow empty input for typing
    }));
  };
  
  const validateForm = () => {
    const { firstName, lastName, email } = customerInfo;
    const newErrors = {};
    
    if (!firstName.trim()) newErrors.firstName = 'First name is required.';
    if (!lastName.trim()) newErrors.lastName = 'Last name is required.';
    if (!email.trim()) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Please enter a valid email address.';
    }
    
    const hasTickets = Object.values(ticketQuantities).some((quantity) => quantity > 0);
    if (!hasTickets) {
      newErrors.ticketQuantities = 'Please specify at least one ticket.';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSendTicket = async () => {
    if (!validateForm()) return;
    
    try {
      console.log(`Fetching event details for EventID: ${eventId}`);
      const eventResponse = await fetch(API_ENDPOINTS.getEvent(eventId));
      console.log('Event API response status:', eventResponse.status);
      
      if (!eventResponse.ok) {
        console.error('Failed to fetch event details. HTTP Status:', eventResponse.status);
        throw new Error('Could not retrieve event details.');
      }
      
      const eventData = await eventResponse.json();
      console.log('Fetched event data:', eventData);
      
      // Extract ticket types and quantities
      const selectedTickets = Object.entries(ticketQuantities)
      .filter(([_, quantity]) => quantity > 0)
      .reduce((acc, [ticketId, quantity]) => {
        const ticket = ticketTypes.find((t) => t.TicketID === parseInt(ticketId, 10));
        if (ticket) {
          acc[ticketId] = { ticketType: ticket.TicketType, quantity };
        }
        return acc;
      }, {});
      
      console.log('Selected tickets for complimentary ticket:', selectedTickets);
      
      // Generate a unique payment reference for complimentary tickets
      const uniquePaymentReference = `complimentary-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
      console.log('Generated unique payment reference:', uniquePaymentReference);
      
      // Prepare payload for email
      const emailPayload = {
        emailType: 'complimentaryTicket',
        emailData: {
          ...customerInfo,
          type: Object.values(selectedTickets).map((details) => details.ticketType).join(', '),
          quantity: Object.values(selectedTickets).map((details) => details.quantity).join(', '),
          eventName: eventData.EventName,
          eventOrganizer: eventData.OrganizerName,
          eventCity: eventData.EventCity,
          eventCountry: eventData.EventCountry,
          eventDate: formatDate(eventData.EventDate),
          startTime: eventData.StartTime,
          endTime: eventData.EndTime,
          doorsOpenTime: eventData.DoorsOpenTime,
          venueName: eventData.VenueName,
          venueAddress: eventData.VenueAddress,
          venueDetails: eventData.VenueDetails,
          theme: eventData.Theme,
          dressCode: eventData.DressCode,
          accessibilityInfo: eventData.AccessibilityInfo,
          covidProtocol: eventData.CovidProtocol,
          aboutEvent: eventData.AboutEvent,
          posterImagePath: `https://eventtop.ca/api1${eventData.PosterImagePath}`,
          contactEmail: eventData.ContactEmail,
          contactPhone: eventData.ContactPhone,
        },
      };
      
      console.log('Prepared email payload:', emailPayload);
      
      // Send email
      setLoading(true);
      const emailResponse = await fetch(API_ENDPOINTS.sendMail, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(emailPayload),
      });
      
      console.log('Email API response status:', emailResponse.status);
      
      if (emailResponse.ok) {
        console.log('Complimentary ticket email sent successfully.');
        
        const userID = localStorage.getItem('userId');
        if (!userID) {
          console.warn('Warning: userID not found in local storage.');
        }
        
        // Save complimentary ticket information to the database
        const customerData = {
          eventID: eventData.EventID,
          firstName: customerInfo.firstName,
          lastName: customerInfo.lastName,
          email: customerInfo.email,
          phone: 'None',
          address: 'None',
          ticketType: Object.keys(selectedTickets)
          .map((ticketId) => selectedTickets[ticketId].ticketType)
          .join(', '),
          quantity: Object.values(selectedTickets).reduce((acc, ticket) => acc + ticket.quantity, 0),
          totalAmount: '0',
          convenienceFee: 0,
          processingFee: 0,
          salesTax: 0,
          paymentReference: uniquePaymentReference, // Use the unique reference here
          userID: userID,
        };
        
        console.log('Customer data for DB saving:', customerData);
        
        try {
          const saveResponse = await fetch(API_ENDPOINTS.saveCustomerInfo, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(customerData),
          });
          
          console.log('Save API response status:', saveResponse.status);
          
          if (saveResponse.ok) {
            const saveResult = await saveResponse.json();
            console.log('Save API response data:', saveResult);
            console.log('Complimentary ticket information saved successfully.');
            alert('Complimentary ticket sent and saved successfully!');
            setShowModal(false);
            setCustomerInfo({ firstName: '', lastName: '', email: '' }); // Reset form
            setTicketQuantities({});
          } else {
            const saveErrorText = await saveResponse.text();
            console.error('Failed to save complimentary ticket information:', saveErrorText);
            setErrors({ general: 'Failed to save ticket information. Please try again.' });
          }
        } catch (saveError) {
          console.error('Error saving complimentary ticket information:', saveError);
          setErrors({ general: 'An error occurred while saving ticket information. Please try again.' });
        }
      } else {
        const emailErrorText = await emailResponse.text();
        console.error('Failed to send email:', emailErrorText);
        setErrors({ general: `Failed to send ticket: ${emailErrorText}` });
      }
    } catch (error) {
      console.error('Error during ticket sending and saving process:', error);
      setErrors({ general: 'An error occurred. Please try again.' });
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <>
    <Button 
    variant="outline-primary" 
    onClick={() => setShowModal(true)} 
    className="btn-sm"
    style={{ fontSize: '10px', padding: '2px 5px' }}
    >
    <i className="fas fa-gift"></i> Complimentary Ticket
    </Button>
    
    <Modal show={showModal} onHide={() => setShowModal(false)}>
    <Modal.Header closeButton>
    <Modal.Title>Send Complimentary Ticket</Modal.Title>
    </Modal.Header>

    <Modal.Body>
    {eventData ? (
      <div className="mb-3" style={{ fontSize: '14px', lineHeight: '1.6' }}>
      <h5 style={{ fontSize: '16px', marginBottom: '10px' }}>Event Information</h5>
      <p style={{ margin: '5px 0' }}><strong>Event Name:</strong> {eventData.EventName}</p>
      <p style={{ margin: '5px 0' }}><strong>Organizer:</strong> {eventData.OrganizerName}</p>
      <p style={{ margin: '5px 0' }}><strong>Date:</strong> {formatDate(eventData.EventDate)}</p>
      <p style={{ margin: '5px 0' }}><strong>Venue:</strong> {eventData.VenueName}</p>
      <hr />
      </div>
    ) : (
      <p>Loading event information...</p>
    )}
    {errors.general && <p className="text-danger">{errors.general}</p>}
    
    <Form>
    <Form.Group className="mb-3">
    <Form.Label>First Name *</Form.Label>
    <Form.Control
    type="text"
    name="firstName"
    placeholder="Enter first name"
    value={customerInfo.firstName}
    onChange={handleInputChange}
    isInvalid={!!errors.firstName}
    />
    <Form.Control.Feedback type="invalid">
    {errors.firstName}
    </Form.Control.Feedback>
    </Form.Group>
    <Form.Group className="mb-3">
    <Form.Label>Last Name *</Form.Label>
    <Form.Control
    type="text"
    name="lastName"
    placeholder="Enter last name"
    value={customerInfo.lastName}
    onChange={handleInputChange}
    isInvalid={!!errors.lastName}
    />
    <Form.Control.Feedback type="invalid">
    {errors.lastName}
    </Form.Control.Feedback>
    </Form.Group>
    <Form.Group className="mb-3">
    <Form.Label>Email *</Form.Label>
    <Form.Control
    type="email"
    name="email"
    placeholder="Enter email"
    value={customerInfo.email}
    onChange={handleInputChange}
    isInvalid={!!errors.email}
    />
    <Form.Control.Feedback type="invalid">
    {errors.email}
    </Form.Control.Feedback>
    </Form.Group>
    <Form.Group className="mb-3">
    <Form.Label>Tickets</Form.Label>
    {ticketTypes.length > 0 ? (
      ticketTypes.map((type) => (
        <div key={type.TicketID} className="d-flex align-items-center mb-2">
        <span className="me-3">{type.TicketType}:</span>
        <Form.Control
        type="number"
        min="0"
        value={ticketQuantities[type.TicketID] || ''}
        onChange={(e) => handleQuantityChange(type.TicketID, e.target.value)}
        className="w-25"
        inputMode="numeric" // Ensures numeric keyboard on mobile
        pattern="[0-9]*"    // Restricts input to numbers only
        />
        </div>
      ))
    ) : (
      <p>No ticket types available for this event.</p>
    )}
    {errors.ticketQuantities && <p className="text-danger">{errors.ticketQuantities}</p>}
    </Form.Group>
    </Form>
    </Modal.Body>


    <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
    Cancel
    </Button>
    <Button
    variant="primary"
    onClick={handleSendTicket}
    disabled={loading}
    >
    {loading ? 'Sending...' : 'Send Ticket'}
    </Button>
    </Modal.Footer>
    </Modal>
    </>
  );
};

export default SendComplimentaryTicket;